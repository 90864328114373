<template>
  <div class="footer bg-white p-5 text-center">
    Diday © 2022
  </div>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
</style>
