<template>
  <div class="d-flex flex-column flex-root">
    <Menu />
    <b-container id="main" style="margin-top:60px;margin-bottom:60px">
      <router-view />
    </b-container>
    <Footer />
  </div>
</template>
<script>
import Menu from './Menu';
import Footer from './Footer';
export default {
  name: "Main",
  components: {
    Menu, Footer
  },
  data: () => ({

  })
}
</script>
<style>
body {
  background: linear-gradient(172deg, rgb(var(--vs-secondary)), rgb(var(--vs-primary)));
  background-attachment: fixed;
  background-repeat: no-repeat;
}
#body {
  display: flow-root;
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1420px;

}
</style>
