<template>
  <vs-navbar square fixed shadow center-collapsed v-model="active">
  <!-- <vs-navbar fixed shadow center-collapsed v-model="active"> -->
    <template #left>
      <img src="@/assets/img/logo.png" alt="" style="max-width: 150px; max-height: 60px;">
    </template>
    <vs-navbar-item to="/home" :active="active == 'home'" id="home">
      Home
    </vs-navbar-item>
    <vs-navbar-item :active="active == 'docs'" id="docs">
      Documents
    </vs-navbar-item>
    <vs-navbar-item :active="active == 'components'" id="components">
      Components
    </vs-navbar-item>
    <vs-navbar-item :active="active == 'license'" id="license">
      License
    </vs-navbar-item>
    <template #right>
      <vs-button color="secondary" flat >Connexion</vs-button>
      <vs-button to="/nouveau">Votre offre</vs-button>
    </template>
  </vs-navbar>
</template>
<script>
export default {
  name: "Menu",
  data: () => ({
    active: 'home'
  })
}
</script>
<style scoped>
.vs-navbar__item.active {
  color: rgb(var(--vs-primary))
}
</style>
